import React, { useMemo } from 'react'
import { RHLayout } from '../components/Layout/RHLayout'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import backend from '../../../services/backend'
import { faFolderPlus, faLock } from '@fortawesome/pro-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import {
  ButtonWithIcon,
  notification,
  SubscriptionModal,
} from '@olystic/design_system_3'
import CreateBaseModal from '../components/CreateBaseModal/CreateBaseModal'
import useModal from '../../../hooks/useModal'
import { useTranslation } from 'react-i18next'
import { RHContainer } from '../components/Layout/RHContainer'
import { VIEW_SONDES } from '../components/Layout/RHPage'
import useRHPageContext from '../../../hooks/useRHPageContext'
import { RHBaseList } from '../components/RHList/RHBaseList'
import { SurveyView } from './SurveyView'
import { useSubscription } from '../../../hooks/useSubscription'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons'

function RHHome() {
  const { details } = useRHPageContext()
  const { t } = useTranslation()
  const { canCreateBase } = useSubscription()
  const createBaseModal = useModal(handleCreate)
  const subscriptionModal = useModal()
  const navigate = useNavigate()
  const allBases = useQuery(['rhbases'], backend.rh.getAllBase)
  const queryClient = useQueryClient()
  const createBase = useMutation((base) => backend.rh.createBase(base), {
    onSuccess: () => {
      queryClient.invalidateQueries(['rhbases'])
    },
    onError: (error) => {
      console.log(error)
      notification.error(t('notification.try_again'), 'lg')
    },
  })

  const deleteBase = useMutation((base) => backend.rh.deleteBase(base), {
    onSuccess: () => {
      queryClient.invalidateQueries(['rhbases'])
    },
    onError: (error) => {
      console.log(error)
      notification.error(t('notification.try_again'), 'lg')
    },
  })

  const updateBase = useMutation((base) => backend.rh.updateBase(base), {
    onSuccess: () => {
      queryClient.invalidateQueries(['rhbases'])
    },
    onError: (error) => {
      console.log(error)
      notification.error(t('notification.try_again'), 'lg')
    },
  })

  function handleEdit(base) {
    details.setView(VIEW_SONDES)
    navigate(`/rh/${base._id}`)
  }

  function handleDelete(base) {
    deleteBase.mutate(base)
  }

  function handleCreate(base) {
    createBase.mutate(base)
  }

  function handleEditName(base) {
    updateBase.mutate(base)
  }

  function handleClickButton() {
    return canCreateBase ? createBaseModal.onShow() : subscriptionModal.onShow()
  }

  return (
    <RHLayout>
      <RHLayout.Header title={t('rh.home.title')}>
        <div className='d-flex align-items-center gap-2 text-blue'>
          <a
            href='https://olystic.zendesk.com/hc/fr/articles/25644677788434-Qu-est-ce-qu-une-base-de-sond%C3%A9s-de-r%C3%A9f%C3%A9rence'
            target='_blank'
            rel='noopener noreferrer'
            className='text-blue text-decoration-underline fw-semibold'
          >
            Qu'est-ce qu'une base de sondés de référence ?
          </a>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='fa-xs' />
        </div>
        <ButtonWithIcon
          onClick={handleClickButton}
          icon={canCreateBase ? faFolderPlus : faLock}
          className='position-absolute'
          style={{ top: '3rem', right: '5rem' }}
          id='CreateBaseButton'
        >
          {t('rh.home.cta')}
        </ButtonWithIcon>
        <BaseView
          bases={allBases}
          onCreate={() => createBaseModal.onShow()}
          onEdit={handleEdit}
          onEditName={handleEditName}
          onDelete={handleDelete}
        />
      </RHLayout.Header>
      <RHLayout.Body>
        <RHContainer>
          <SurveyView bases={allBases} onEdit={handleEdit} />
        </RHContainer>
      </RHLayout.Body>
      <CreateBaseModal {...createBaseModal.modalProps} />
      <SubscriptionModal {...subscriptionModal.modalProps} />
    </RHLayout>
  )
}

function BaseView({ bases, onDelete, onEdit, onEditName }) {
  const data = useMemo(() => {
    if (bases.data) {
      return bases.data.filter((base) => !base.survey && !base.parentGroup)
    }
    return []
  }, [bases.data])

  return (
    <div id='RHBaseList'>
      <RHBaseList
        items={data}
        onDelete={onDelete}
        onEdit={onEdit}
        onEditName={onEditName}
        isLoading={bases.isLoading}
      />
    </div>
  )
}

export default RHHome
