import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ListSettings from '../RHManagement/listSettings'
import exportExcel, {
  defaultSheetFormat,
} from '../../../../lib/exportExcel/exportCsv'
import { useSettings } from '../../../../hooks/useSettings'
import { useRHBase } from '../../../../hooks/useRHBase'
import { useParams } from 'react-router-dom'

export default function ImportSettingModal({ show, onHide }) {
  const { t } = useTranslation()
  const { id } = useParams()
  const base = useRHBase(id)

  const { setting } = useSettings(
    base.isSurvey ? 'survey' : 'perimeter',
    base.isSurvey ? base.isSurvey : base.base.data?.perimeter,
  )

  return (
    <Modal size='xl' centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('component.importSettingModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ImportSetting setting={setting} />
      </Modal.Body>
    </Modal>
  )
}

export function ImportSetting({ setting, confirm, onConfirm }) {
  const { t } = useTranslation()

  const handleExportVariables = () => {
    const exportData = setting.variables.map((v) => ({
      'Variables Obligatoires': t(`category.${v.name}`),
      'Modalités': v.modalities.join(', '),
    }))

    exportExcel(exportData, defaultSheetFormat(), 'variables')
  }

  return (
    <div>
      {!setting || !setting.variables.length ? (
        <p>{t('component.importSetting.noSetting')}</p>
      ) : (
        <>
          <div className='d-flex align-items-start justify-content-between mb-3'>
            <div>
              <h4>{t('component.importSetting.heading')}</h4>
              <p className='pb-3 text-black-50'>
                {t('component.importSetting.text')}
              </p>
            </div>

            <Button onClick={handleExportVariables}>
              {t('common.download')}
            </Button>
          </div>

          <ListSettings setting={setting} />
        </>
      )}

      {onConfirm && (
        <Form.Check
          id='confirm'
          name='confirm'
          className='mb-0 mt-5 fs-lg fw-semibold'
          checked={confirm}
          onChange={onConfirm}
          label={t('component.importSetting.confirm')}
        />
      )}
    </div>
  )
}
