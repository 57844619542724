import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { deepCopy } from '../../../../lib/functions'
import { ButtonWithIcon, TextInputFormik } from '@olystic/design_system_3'
import { isAfter } from 'date-fns'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faGear } from '@fortawesome/pro-regular-svg-icons'
import { useParams } from 'react-router-dom'
import { useRHBase } from '../../../../hooks/useRHBase'
import { useSettings } from '../../../../hooks/useSettings'
import { ImportSetting } from '../RHSettings/importSetting'

function isCategoryRequired(category, requiredCategories) {
  return requiredCategories.includes(category)
}

export const defaultRespondent = {
  email: '',
  matricule: '',
  rhData: {
    ps_age: '',
    ps_agence: '',
    ps_anciennete: '',
    ps_bu: '',
    ps_classification: '',
    ps_classification_niv_1: '',
    ps_classification_niv_2: '',
    ps_contrat: '',
    ps_departement: '',
    ps_direction: '',
    ps_division: '',
    ps_emploi: '',
    ps_entreprise: '',
    ps_etablissement: '',
    ps_famille_metiers: '',
    ps_genre: '',
    ps_manager: '',
    ps_metier: '',
    ps_niveau_managerial: '',
    ps_nom: '',
    ps_pays: '',
    ps_perimetre: '',
    ps_plaque: '',
    ps_prenom: '',
    ps_region: '',
    ps_service: '',
    ps_sexe: '',
    ps_site: '',
    ps_societe: '',
    ps_status: '',
    ps_sortie: '',
  },
}

export const RespondentSchema = (categories, t) =>
  yup.object().shape({
    email: yup.string().when([], {
      is: () => isCategoryRequired('ps_email', categories),
      then: yup.string().email().required(t('rh.respondent_form.required')),
      otherwise: yup.string().email(),
    }),
    matricule: yup.string().when([], {
      is: () => isCategoryRequired('ps_matricule', categories),
      then: yup
        .string()
        .trim()
        .min(1, 'rh.respondent_form.matricule.error.min')
        .required(t('rh.respondent_form.required')),
      otherwise: yup
        .string()
        .trim()
        .min(1, 'rh.respondent_form.matricule.error.min'),
    }),
    rhData: yup.object().shape({
      ps_nom: yup.string().when([], {
        is: () => isCategoryRequired('ps_nom', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_prenom: yup.string().when([], {
        is: () => isCategoryRequired('ps_prenom', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_age: yup.date().when([], {
        is: () => isCategoryRequired('ps_age', categories),
        then: yup.date().required(t('rh.respondent_form.required')),
        otherwise: yup.date(),
      }),
      ps_anciennete: yup.date().when([], {
        is: () => isCategoryRequired('ps_anciennete', categories),
        then: yup.date().required(t('rh.respondent_form.required')),
        otherwise: yup.date(),
      }),
      ps_direction: yup.string().when([], {
        is: () => isCategoryRequired('ps_direction', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_status: yup.string().when([], {
        is: () => isCategoryRequired('ps_status', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_manager: yup.string().when([], {
        is: () => isCategoryRequired('ps_manager', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_site: yup.string().when([], {
        is: () => isCategoryRequired('ps_site', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_service: yup.string().when([], {
        is: () => isCategoryRequired('ps_service', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_pays: yup.string().when([], {
        is: () => isCategoryRequired('ps_pays', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_metier: yup.string().when([], {
        is: () => isCategoryRequired('ps_metier', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_agence: yup.string().when([], {
        is: () => isCategoryRequired('ps_agence', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_bu: yup.string().when([], {
        is: () => isCategoryRequired('ps_bu', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_classification: yup.string().when([], {
        is: () => isCategoryRequired('ps_classification', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_classification_niv_1: yup.string().when([], {
        is: () => isCategoryRequired('ps_classification_niv_1', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_classification_niv_2: yup.string().when([], {
        is: () => isCategoryRequired('ps_classification_niv_2', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_contrat: yup.string().when([], {
        is: () => isCategoryRequired('ps_contrat', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_departement: yup.string().when([], {
        is: () => isCategoryRequired('ps_departement', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_division: yup.string().when([], {
        is: () => isCategoryRequired('ps_division', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_emploi: yup.string().when([], {
        is: () => isCategoryRequired('ps_emploi', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_entreprise: yup.string().when([], {
        is: () => isCategoryRequired('ps_entreprise', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_etablissement: yup.string().when([], {
        is: () => isCategoryRequired('ps_etablissement', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_famille_metiers: yup.string().when([], {
        is: () => isCategoryRequired('ps_famille_metiers', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_genre: yup.string().when([], {
        is: () => isCategoryRequired('ps_genre', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_niveau_managerial: yup.string().when([], {
        is: () => isCategoryRequired('ps_niveau_managerial', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_perimetre: yup.string().when([], {
        is: () => isCategoryRequired('ps_perimetre', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_plaque: yup.string().when([], {
        is: () => isCategoryRequired('ps_plaque', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_region: yup.string().when([], {
        is: () => isCategoryRequired('ps_region', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_sexe: yup.string().when([], {
        is: () => isCategoryRequired('ps_sexe', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_societe: yup.string().when([], {
        is: () => isCategoryRequired('ps_societe', categories),
        then: yup.string().required(t('rh.respondent_form.required')),
        otherwise: yup.string(),
      }),
      ps_sortie: yup.date().when([], {
        is: () => isCategoryRequired('ps_sortie', categories),
        then: yup.date().required(t('rh.respondent_form.required')),
        otherwise: yup.date(),
      }),
    }),
  })

function formDateFormat(dateString) {
  let toConvert = dateString
  if (typeof toConvert === 'object') toConvert = toConvert?.date
  if (toConvert) {
    const date = moment(toConvert)
    if (date.isValid()) {
      const d = date.format('YYYY-MM-DD')
      if (d === '1800-01-01') return ''
      return date.format('YYYY-MM-DD')
    }
  }
  return ''
}

export function toFormFormat(respondent) {
  if (!respondent) return deepCopy(defaultRespondent)
  // TODO unit test
  const formatted = {
    email: respondent.email || '',
    matricule: respondent.matricule || '',
  }
  if (respondent._id) formatted._id = respondent._id
  formatted.rhData = Object.assign(
    { ...defaultRespondent.rhData },
    respondent.rhData,
  )
  formatted.rhData.ps_age = formDateFormat(formatted.rhData.ps_age)
  formatted.rhData.ps_anciennete = formDateFormat(
    formatted.rhData.ps_anciennete,
  )
  formatted.rhData.ps_sortie = formDateFormat(formatted.rhData.ps_sortie)

  for (const key in formatted.rhData) {
    if (formatted.rhData[key] === 'undefined') {
      formatted.rhData[key] = ''
    }
  }

  return formatted
}

export function toBackendFormat(values) {
  const formatted = deepCopy(values)
  formatted.email = formatted.email.trim()
  formatted.matricule = formatted.matricule.trim()
  for (const prop of Object.keys(formatted.rhData)) {
    if (!formatted.rhData[prop]?.trim()) delete formatted.rhData[prop]
  }
  if (formatted.rhData.ps_manager)
    formatted.rhData.ps_manager = formatted.rhData.ps_manager === 'Manager'

  return formatted
}

function RespondentForm({
  respondent,
  onCreate,
  onUpdate,
  edit,
  submitText,
  categories,
}) {
  const { t } = useTranslation()
  const respondentData = useMemo(() => toFormFormat(respondent), [respondent])
  const [showSettings, setShowSettings] = useState(false)

  const { id } = useParams()
  const base = useRHBase(id)

  const { setting } = useSettings(
    base.isSurvey ? 'survey' : 'perimeter',
    base.isSurvey ? base.isSurvey : base.base.data?.perimeter,
  )

  function renderLabel(categorieName) {
    return (
      <div className='d-flex align-items-center gap-2'>
        {t(`category.${categorieName}`)}
        {isCategoryRequired(categorieName, categories) && (
          <FontAwesomeIcon icon={faCircleExclamation} color='red' />
        )}
      </div>
    )
  }

  return (
    <Formik
      initialValues={respondentData}
      validationSchema={RespondentSchema(categories, t)}
      onSubmit={async (values, actions) => {
        if (edit) await onUpdate(toBackendFormat(values), actions)
        else await onCreate(toBackendFormat(values), actions)
      }}
    >
      {(formik) => {
        return (
          <Form noValidate>
            <Row>
              {showSettings && (
                <Col
                  xxl={4}
                  className='me-5 pe-5 border-end border-2 border-dark'
                >
                  <ImportSetting setting={setting} />
                </Col>
              )}
              <Col>
                <Row>
                  <Col>
                    <fieldset>
                      <legend>
                        {t('rh.respondent_form.legend.individual_data')}
                      </legend>
                      <TextInputFormik
                        name='matricule'
                        label={renderLabel('ps_matricule')}
                        placeholder={t(
                          'rh.respondent_form.matricule.placeholder',
                        )}
                        disabled={edit}
                      />

                      <Row className='my-3'>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_nom'
                            label={renderLabel('ps_nom')}
                            placeholder={t(
                              'rh.respondent_form.nom.placeholder',
                            )}
                          />
                        </Col>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_prenom'
                            label={renderLabel('ps_prenom')}
                            placeholder={t(
                              'rh.respondent_form.prenom.placeholder',
                            )}
                          />
                        </Col>
                      </Row>

                      <TextInputFormik
                        name='email'
                        label={renderLabel('ps_email')}
                        placeholder={t('rh.respondent_form.email.placeholder')}
                        type='email'
                        className='my-3'
                      />

                      <TextInputFormik
                        name='rhData.ps_age'
                        label={renderLabel('ps_age')}
                        className='my-3'
                        type='date'
                        isInvalid={isAfter(
                          new Date(formik.values.rhData.ps_age),
                          new Date(),
                        )}
                      />

                      <Row>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_sexe'
                            label={renderLabel('ps_sexe')}
                            placeholder={t(
                              'rh.respondent_form.sexe.placeholder',
                            )}
                          />
                        </Col>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_genre'
                            label={renderLabel('ps_genre')}
                            placeholder={t(
                              'rh.respondent_form.genre.placeholder',
                            )}
                          />
                        </Col>
                      </Row>
                    </fieldset>
                  </Col>
                  <Col>
                    <fieldset>
                      <legend>{t('rh.respondent_form.legend.job_data')}</legend>

                      <TextInputFormik
                        name='rhData.ps_status'
                        label={renderLabel('ps_status')}
                        placeholder={t('rh.respondent_form.statut.placeholder')}
                      />

                      <Row className='my-3'>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_emploi'
                            label={renderLabel('ps_emploi')}
                            placeholder={t(
                              'rh.respondent_form.emploi.placeholder',
                            )}
                          />
                        </Col>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_contrat'
                            label={renderLabel('ps_contrat')}
                            placeholder={t(
                              'rh.respondent_form.contrat.placeholder',
                            )}
                          />
                        </Col>
                      </Row>

                      <TextInputFormik
                        name='rhData.ps_classification'
                        label={renderLabel('ps_classification')}
                        placeholder={t(
                          'rh.respondent_form.classification.placeholder',
                        )}
                      />

                      <Row className='my-3'>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_classification_niv_1'
                            label={renderLabel('ps_classification_niv_1')}
                            placeholder={t(
                              'rh.respondent_form.classification_niv_1.placeholder',
                            )}
                          />
                        </Col>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_classification_niv_2'
                            label={renderLabel('ps_classification_niv_2')}
                            placeholder={t(
                              'rh.respondent_form.classification_niv_2.placeholder',
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className='my-3'>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_famille_metiers'
                            label={renderLabel('ps_famille_metiers')}
                            placeholder={t(
                              'rh.respondent_form.famille_metiers.placeholder',
                            )}
                          />
                        </Col>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_metier'
                            label={renderLabel('ps_metier')}
                            placeholder={t(
                              'rh.respondent_form.metier.placeholder',
                            )}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_niveau_managerial'
                            label={renderLabel('ps_niveau_managerial')}
                            placeholder={t(
                              'rh.respondent_form.niveau_managerial.placeholder',
                            )}
                          />
                        </Col>

                        <Col>
                          <Form.Label>{renderLabel('ps_manager')}</Form.Label>
                          <Form.Select
                            id='rhData.ps_manager'
                            value={formik.values.rhData?.ps_manager}
                            onChange={formik.handleChange}
                            isInvalid={
                              formik.touched.rhData?.ps_manager &&
                              formik.errors.rhData?.ps_manager
                            }
                          >
                            <option value=''>
                              {t('rh.respondent_form.manager.option1')}
                            </option>
                            <option value='Manager'>
                              {t('rh.respondent_form.manager.option2')}
                            </option>
                            <option value='Non-manager'>
                              {t('rh.respondent_form.manager.option3')}
                            </option>
                          </Form.Select>
                          <Form.Control.Feedback type='invalid'>
                            {t(formik.errors.rhData?.ps_manager)}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </fieldset>
                  </Col>
                  <Col>
                    <fieldset>
                      <legend>
                        {t('rh.respondent_form.legend.organization_data')}
                      </legend>

                      <TextInputFormik
                        name='rhData.ps_societe'
                        label={renderLabel('ps_societe')}
                        placeholder={t(
                          'rh.respondent_form.societe.placeholder',
                        )}
                      />

                      <Row className='my-3'>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_entreprise'
                            label={renderLabel('ps_entreprise')}
                            placeholder={t(
                              'rh.respondent_form.entreprise.placeholder',
                            )}
                          />
                        </Col>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_bu'
                            label={renderLabel('ps_bu')}
                            placeholder={t('rh.respondent_form.bu.placeholder')}
                          />
                        </Col>
                      </Row>

                      <Row className='my-3'>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_division'
                            label={renderLabel('ps_division')}
                            placeholder={t(
                              'rh.respondent_form.division.placeholder',
                            )}
                          />
                        </Col>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_direction'
                            label={renderLabel('ps_direction')}
                            placeholder={t(
                              'rh.respondent_form.direction.placeholder',
                            )}
                          />
                        </Col>
                      </Row>

                      <Row className='my-3'>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_departement'
                            label={renderLabel('ps_departement')}
                            placeholder={t(
                              'rh.respondent_form.departement.placeholder',
                            )}
                          />
                        </Col>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_perimetre'
                            label={renderLabel('ps_perimetre')}
                            placeholder={t(
                              'rh.respondent_form.perimetre.placeholder',
                            )}
                          />
                        </Col>
                      </Row>

                      <Row className='my-3'>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_etablissement'
                            label={renderLabel('ps_etablissement')}
                            placeholder={t(
                              'rh.respondent_form.etablissement.placeholder',
                            )}
                          />
                        </Col>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_agence'
                            label={renderLabel('ps_agence')}
                            placeholder={t(
                              'rh.respondent_form.agence.placeholder',
                            )}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_service'
                            label={renderLabel('ps_service')}
                            placeholder={t(
                              'rh.respondent_form.service.placeholder',
                            )}
                          />
                        </Col>
                      </Row>
                    </fieldset>
                  </Col>
                </Row>

                <Row>
                  <Col xs={4}>
                    <fieldset className='mt-3 auto'>
                      <legend>{t('rh.respondent_form.legend.in_out')}</legend>

                      <Row className='mb-3'>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_anciennete'
                            label={renderLabel('ps_anciennete')}
                            type='date'
                            isInvalid={isAfter(
                              new Date(formik.values.rhData.ps_anciennete),
                              new Date(),
                            )}
                          />
                        </Col>

                        <Col>
                          <TextInputFormik
                            name='rhData.ps_sortie'
                            label={renderLabel('ps_sortie')}
                            type='date'
                          />
                        </Col>
                      </Row>
                    </fieldset>
                  </Col>

                  <Col xs={8}>
                    <fieldset className='mt-3 auto'>
                      <legend>
                        {t('rh.respondent_form.legend.geographic_data')}
                      </legend>

                      <Row className='mb-3'>
                        <Col>
                          <TextInputFormik
                            name='rhData.ps_plaque'
                            label={renderLabel('ps_plaque')}
                            placeholder={t(
                              'rh.respondent_form.plaque.placeholder',
                            )}
                          />
                        </Col>

                        <Col>
                          <TextInputFormik
                            name='rhData.ps_pays'
                            label={renderLabel('ps_pays')}
                            placeholder={t(
                              'rh.respondent_form.pays.placeholder',
                            )}
                          />
                        </Col>

                        <Col>
                          <TextInputFormik
                            name='rhData.ps_region'
                            label={renderLabel('ps_region')}
                            placeholder={t(
                              'rh.respondent_form.region.placeholder',
                            )}
                          />
                        </Col>

                        <Col>
                          <TextInputFormik
                            name='rhData.ps_site'
                            label={renderLabel('ps_site')}
                            placeholder={t(
                              'rh.respondent_form.site.placeholder',
                            )}
                          />
                        </Col>
                      </Row>
                    </fieldset>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col className='d-flex justify-content-end gap-2'>
                <ButtonWithIcon
                  onClick={() => setShowSettings(!showSettings)}
                  icon={faGear}
                  variant='dark'
                >
                  {t('rh.detail.settings.cta')}
                </ButtonWithIcon>
                <Button
                  onClick={formik.handleSubmit}
                  disabled={formik.isSubmitting}
                >
                  {submitText}
                </Button>
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

RespondentForm.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  submitText: PropTypes.string.isRequired,
  respondent: PropTypes.shape({
    email: PropTypes.string,
    matricule: PropTypes.string,
    rhData: PropTypes.shape({
      ps_nom: PropTypes.string,
      ps_prenom: PropTypes.string,
      ps_age: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ date: PropTypes.string }),
      ]),
      ps_anciennete: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ date: PropTypes.string }),
      ]),
      ps_direction: PropTypes.string,
      ps_status: PropTypes.string,
      ps_manager: PropTypes.string,
      ps_site: PropTypes.string,
      ps_service: PropTypes.string,
      ps_pays: PropTypes.string,
      ps_metier: PropTypes.string,
      ps_agence: PropTypes.string,
      ps_bu: PropTypes.string,
      ps_classification: PropTypes.string,
      ps_classification_niv_1: PropTypes.string,
      ps_classification_niv_2: PropTypes.string,
      ps_contrat: PropTypes.string,
      ps_departement: PropTypes.string,
      ps_division: PropTypes.string,
      ps_emploi: PropTypes.string,
      ps_entreprise: PropTypes.string,
      ps_etablissement: PropTypes.string,
      ps_famille_metiers: PropTypes.string,
      ps_genre: PropTypes.string,
      ps_niveau_managerial: PropTypes.string,
      ps_perimetre: PropTypes.string,
      ps_plaque: PropTypes.string,
      ps_region: PropTypes.string,
      ps_sexe: PropTypes.string,
      ps_societe: PropTypes.string,
    }),
  }),
}

RespondentForm.defaultProps = {
  respondent: defaultRespondent,
}

export default RespondentForm
