import React, { useState } from 'react'
import { ButtonWithIcon } from '@olystic/design_system_3'
import ImportSettingModal from './importSetting'
import { useTranslation } from 'react-i18next'
import { faGear } from '@fortawesome/pro-regular-svg-icons'

export function SettingsButton() {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  return (
    <>
      <ButtonWithIcon
        onClick={() => setShow(true)}
        icon={faGear}
        variant='dark'
      >
        {t('rh.detail.settings.cta')}
      </ButtonWithIcon>

      {show && <ImportSettingModal onHide={() => setShow(false)} show={show} />}
    </>
  )
}
