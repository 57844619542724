/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import GanttList from './GanttList'
import { surveyProps } from '@olystic/common/proptypes/survey'
import moment from 'moment'
import { mappingSurvey } from '@olystic/common/survey.mjs'
import { DATE_FORMAT } from '../../../lib/date/useDateFormat'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// Translation
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons'

export default function GanttListConnector({ info, surveys }) {
  const { t } = useTranslation()

  const data = useMemo(() => {
    return surveys.map((s) => ({
      name: s.name,
      type: s.questionnaire.type,
      launch: s.dates.Lancement,
      end: s.dates.Clôture,
      respondentGroup: s?.respondentGroup,
    }))
  }, [surveys])

  const columns = React.useMemo(() => {
    return [
      {
        Header: t('component.gantt.title'),
        accessor: 'name',
        disableSortBy: true,
        Cell: ({
          row: {
            values: { name, type },
          },
        }) => {
          return (
            <div className='d-flex text-start align-items-center ps-5'>
              <div
                className={`p-2 rounded-1 me-3 bg-${mappingSurvey(type).color}`}
              />
              <p className='m-0 text-truncate' style={{ maxWidth: '9rem' }}>
                {name}
              </p>
            </div>
          )
        },
      },
      {
        Header: t('component.gantt.list.col1'),
        accessor: 'type',
        Cell: ({ cell: { value } }) => {
          return (
            <Badge bg={mappingSurvey(value).color}>
              {t(`survey.types.${mappingSurvey(value).title}`)}
            </Badge>
          )
        },
      },
      {
        Header: t('component.gantt.list.col2'),
        accessor: 'launch',
        Cell: ({ cell: { value } }) => {
          return moment(value).format(t(DATE_FORMAT))
        },
      },
      {
        Header: t('component.gantt.list.col3'),
        accessor: 'end',
        Cell: ({ cell: { value } }) => {
          return moment(value).format(t(DATE_FORMAT))
        },
      },
      {
        Header: t('component.gantt.list.col4'),
        accessor: 'respondentGroup',
        Cell: ({ cell: { value } }) => {
          return value?._id ? (
            <Link to={`/rh/${value._id}`}>
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip
                    id={`respondent-tooltip-${value._id}`}
                    className='position-fixed'
                  >
                    {t('component.gantt.access_rh')}
                  </Tooltip>
                }
              >
                <div className='d-flex align-items-center justify-content-center gap-2'>
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  <span className='text-decoration-underline'>
                    {value.name}
                  </span>
                </div>
              </OverlayTrigger>
            </Link>
          ) : (
            '---'
          )
        },
      },
    ]
  }, [t])

  return (
    <GanttList
      data={data}
      columns={columns}
      summary={info.summary}
      description={info.description}
    />
  )
}

GanttListConnector.propTypes = {
  summary: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  surveys: PropTypes.arrayOf(surveyProps).isRequired,
}
