import React from 'react'
// Components
import Section from '../components/layout/section'
// import TechnicalSupportCard from "../../components/Cards/TechnicalSupportCard/TechnicalSupportCard"
// import MailSupportCard from "../../components/Cards/MailSupportCard/MailSupportCard"
import RHBaseCard from '../components/cards/RHBaseCard/RHBaseCard'
import cnil from '../assets/images/cnil.png'
import insee from '../assets/images/insee.png'
import rgpd from '../assets/images/rgpd.jpg'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons'
// Translation
import { Trans, useTranslation } from 'react-i18next'

// const parametrage = {
//   steps: [
//     { id: "send_info", label: "Envoi des informations techniques", state: "past" },
//     { id: "config", label: "Configuration du paramétrage technique", state: "past" },
//     { id: "send_test", label: "Envoi d’un test", state: "in progress" },
//     { id: "param_system", label: "Paramétrage de vos systèmes", state: "future" },
//   ],
//   status: "in progress",
// }

const LIST = [
  {
    id: 'insee',
    link: 'https://www.insee.fr/fr/statistiques/fichier/1300624/guide_secret_avril_2023.pdf',
    text: 'page.parametrage.security.list.text1',
    logo: insee,
  },
  {
    id: 'cnil',
    link: 'https://www.cnil.fr/fr/principes-cles/guide-de-la-securite-des-donnees-personnelles',
    text: 'page.parametrage.security.list.text2',
    logo: cnil,
  },
  {
    id: 'rgpd',
    link: 'https://www.cnil.fr/fr/comprendre-le-rgpd',
    text: 'page.parametrage.security.list.text3',
    logo: rgpd,
  },
]

export default function Parametrage() {
  const { t } = useTranslation()
  return (
    <>
      <Section className='mt-8'>
        <h3 className='mb-0'>{t('page.parametrage.rh')}</h3>
        <RHBaseCard />
      </Section>
      <Section>
        <h3 className='mt-5 mb-4'>{t('page.parametrage.security.title')}</h3>
        <Trans i18nKey={'page.parametrage.security.description'}>
          <p>
            {' '}
            <span className='fw-semibold'>
              OLYSTIC considère la protection des données à caractère personnel
              et la confidentialité comme essentielles.
            </span>
            Nos équipes mettent tout en oeuvre pour vous garantir le plus haut
            niveau de protection.
          </p>
          <p>
            Olystic s'inscrit dans le respect strict des trois cadres juridiques
            qui régissent la protection des données à caractère personnel.
          </p>
        </Trans>

        <ol>
          {LIST.map((item) => (
            <li key={item.id} className='mb-2'>
              <a
                className='text-primary'
                href={item.link}
                target='_blank'
                rel='noreferrer'
              >
                {t(item.text)}
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className='ms-1'
                  size='xs'
                />
              </a>
            </li>
          ))}
        </ol>

        <div
          className='d-flex justify-content-center gap-9 mt-6'
          style={{ height: '3rem' }}
        >
          {LIST.map((item, i) => (
            <img src={item.logo} alt={`logo - ${item.id}`} />
          ))}
        </div>

        <p className='mt-5'>
          {t('page.parametrage.security.cta1')}{' '}
          <a
            href='https://www.olysticworks.com/politique-de-confidentialite'
            target='_blank'
            rel='noreferrer'
            className='text-primary'
          >
            {t('page.parametrage.security.cta2')}
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              className='ms-1'
              size='xs'
            />
          </a>
        </p>
      </Section>
    </>
  )
}
