import React, { useMemo } from 'react'
import { RHSurveyList } from '../components/RHList/RHSurveyList'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import NoData from '../../../components/NoData/NoData'
import {
  faListUl,
  faComments,
  faArrowUpRightFromSquare,
} from '@fortawesome/pro-solid-svg-icons'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ButtonWithIcon } from '@olystic/design_system_3'

export function SurveyView({ bases, onEdit }) {
  const { t } = useTranslation()
  const data = useMemo(() => {
    if (bases.data) {
      return bases.data.filter(
        (base) =>
          base.survey &&
          !base.parentGroup &&
          moment().isBefore(base.survey.dates.Clôture),
      )
    }
    return []
  }, [bases.data])

  if (!data.length)
    return (
      <>
        <h2 className='m-0'>{t('rh.survey_view.title')}</h2>
        <div className='d-flex align-items-center gap-2 text-blue'>
          <a
            href='https://olystic.zendesk.com/hc/fr/articles/25644625344914-Comment-g%C3%A9rer-vos-enqu%C3%AAtes-en-cours'
            target='_blank'
            rel='noopener noreferrer'
            className='text-blue text-decoration-underline fw-semibold'
          >
            Comment gérer vos enquêtes en cours ?
          </a>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='fa-xs' />
        </div>
        <div className='w-xxl-50 mx-auto mt-5'>
          <NoData
            title={t('component.noQuestionnaireCard.title')}
            text={t('component.noQuestionnaireCard.text')}
            cta={
              <div className='d-flex gap-2'>
                <Link to='/'>
                  <ButtonWithIcon icon={faListUl} className='m-auto'>
                    {t('component.noQuestionnaireCard.cta2')}
                  </ButtonWithIcon>
                </Link>

                <Button
                  onClick={() =>
                    (window.location.href = '/planifier-feedbacks')
                  }
                  variant='primary'
                >
                  <FontAwesomeIcon icon={faComments} className='mr-2' />{' '}
                  {t('component.noQuestionnaireCard.cta3')}
                </Button>
              </div>
            }
          />
        </div>
      </>
    )

  return (
    <>
      <h2>{t('rh.survey_view.title')}</h2>

      <div className='d-flex align-items-center text-danger gap-2'>
        <p className='text-secondary text-width'>
          {t('rh.survey_view.description')}
        </p>
      </div>

      <div className='d-flex align-items-center gap-2 text-blue'>
        <a
          href='https://olystic.zendesk.com/hc/fr/articles/25644625344914-Comment-g%C3%A9rer-vos-enqu%C3%AAtes-en-cours'
          target='_blank'
          rel='noopener noreferrer'
          className='text-blue text-decoration-underline fw-semibold'
        >
          Comment gérer vos enquêtes en cours ?
        </a>
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='fa-xs' />
      </div>

      <RHSurveyList items={data} onEdit={onEdit} />
    </>
  )
}
