import React, { createRef, useEffect, useMemo, useState } from 'react'
import ImportModel from './ImportModel'
import ImportFile from './importFile'
import RHMapping from './RHMapping'
import { ImportReport } from './ImportReportModal'
import PropTypes from 'prop-types'
import ImportStatus from './ImportStatus'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RHStats } from '../RHStats/RHStatsModal'
import { ButtonWithIcon, notification } from '@olystic/design_system_3'
import classNames from 'classnames'
import backend from '../../../../services/backend'
import { useParams } from 'react-router-dom'
import useRHData from '../../../../hooks/useRHData'
import { useRHBase } from '../../../../hooks/useRHBase'
import CreateGroups from './CreateGroup'
import { useSubscription } from '../../../../hooks/useSubscription'
import { ImportSetting } from '../RHSettings/importSetting'
import { downloadChart } from '../../../../lib/functions'
import { useSettings } from '../../../../hooks/useSettings'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft as farChevronLeft,
  faArrowToBottom,
} from '@fortawesome/pro-regular-svg-icons'

RHImport.propTypes = {
  showImportModelModal: PropTypes.bool.isRequired,
  setImportFileModal: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
}

const date_categories_list = ['ps_age', 'ps_anciennete', 'ps_sortie']
const group_list = [
  'ps_sexe',
  'ps_status',
  'ps_site',
  'ps_manager',
  'ps_anciennete',
  'ps_sortie',
]
const MODEL = 'model'
const FILE = 'file'
const MAPPING = 'mapping'
const STATUS = 'status'
const REPORT = 'report'
const STATS = 'stats'
const GROUP = 'group'
const SETTING = 'setting'

const alwaysActiveCategories = [
  'ps_matricule',
  'ps_email',
  'ps_nom',
  'ps_prenom',
]

function RHImport({
  showImportModelModal,
  setImportFileModal,
  onReload,
  baseId,
}) {
  const ref = createRef()

  const { t } = useTranslation()
  const { id } = useParams()
  const base = useRHBase(id)
  const { respondents } = useRHData(id)

  const { setting } = useSettings(
    base.isSurvey ? 'survey' : 'perimeter',
    base.isSurvey ? base.isSurvey : base.base.data?.perimeter,
  )

  const [currentStep, setCurrentStep] = useState(SETTING)
  const [canGo, setCanGo] = useState(true)
  const [rhFile, setRHFile] = useState(null)
  const [PreparseResponse, setPreparseResponse] = useState(null)
  const [report, setReport] = useState(null)
  const [jobStatus, setJobStatus] = useState()
  const [missingVariables, setMissingVariables] = useState([])
  const [mapping, setMapping] = useState({})
  const [columns, setColumns] = useState([])
  const [options, setOptions] = useState([])
  const [groups, setGroups] = useState({})
  const [selectedCategories, setSelectedCategories] = useState([])
  const [confirm, setConfirm] = useState(false)
  const [error, setError] = useState(false)

  const { isFreeSubscription, canCreateGroup, subscriptionQuery } =
    useSubscription()

  const processedData = useMemo(
    () => respondents.data?.processedData || [],
    [respondents.data?.processedData],
  )

  useEffect(() => {
    const groups = {}

    for (let respondent of processedData) {
      for (let [key, value] of Object.entries(respondent.rhData)) {
        if (group_list.includes(key)) {
          if (!groups[key]) {
            groups[key] = new Set()
          }

          if (date_categories_list.includes(key) && value.age !== undefined) {
            groups[key].add(value.group)
          } else if (typeof value === 'string') {
            groups[key].add(value)
          }
        }
      }
    }

    const groupedObject = Object.keys(groups).reduce((acc, key) => {
      if (groups[key].size > 0) {
        acc[key] = Array.from(groups[key]).reduce((innerAcc, groupValue) => {
          innerAcc[groupValue] = false
          return innerAcc
        }, {})
      }
      return acc
    }, {})
    setGroups(groupedObject)
  }, [processedData])

  useEffect(() => {
    if (PreparseResponse) {
      setColumns(Object.entries(PreparseResponse.data.columns))
      setOptions(PreparseResponse.data.options)

      const categoriesArray = PreparseResponse.data.options.filter(
        (opt) => !!PreparseResponse.data.columns[opt.label],
      )

      const autoMapping = Object.assign(
        {},
        ...categoriesArray.map((opt) => ({ [opt.label]: opt })),
      )

      setMapping(autoMapping)
      setSelectedCategories(
        isFreeSubscription
          ? categoriesArray
              .filter((category) =>
                alwaysActiveCategories.includes(category.value),
              )
              .map((category) => category.label)
          : categoriesArray.map((category) => category.label),
      )
    }
  }, [PreparseResponse, isFreeSubscription])

  const nextStep = () => {
    const steps = Object.keys(STEPS)
    switch (currentStep) {
      case MODEL:
        setCanGo(false)
        setCurrentStep((currentStep) => steps[steps.indexOf(currentStep) + 1])
        break
      case MAPPING:
        handleValidate()
        break
      case REPORT:
        finishImport()
        break
      case STATS:
        handleStats()
        break
      case GROUP:
        handleSubmit(groups)
        break

      default:
        setCurrentStep((currentStep) => steps[steps.indexOf(currentStep) + 1])
    }
  }

  const previousStep = () => {
    const steps = Object.keys(STEPS)
    switch (currentStep) {
      case MODEL:
        setCurrentStep((currentStep) => steps[steps.indexOf(currentStep) - 1])
        setCanGo(true)
        setConfirm(false)
        break
      case FILE:
      case MAPPING:
        setCurrentStep((currentStep) => steps[steps.indexOf(currentStep) - 1])
        setCanGo(false)
        break
      case REPORT:
        setCurrentStep((currentStep) => steps[steps.indexOf(currentStep) - 2])
        break
      default:
        setCurrentStep((currentStep) => steps[steps.indexOf(currentStep) - 1])
        setError(false)
    }
  }

  const closeModal = () => {
    setCurrentStep(SETTING)
    setImportFileModal(false)
    setConfirm(false)
  }

  const handleFirstParsing = (acceptedFiles) => {
    const form = new FormData()
    form.set('file', acceptedFiles[0])
    backend.parseRH
      .preparse(form)
      .then((response) => {
        setCanGo(true)
        setPreparseResponse(response)
        setRHFile(acceptedFiles[0])
      })
      .catch((uploadError) => {
        console.log(uploadError)
      })
  }

  const finishRHMapping = (jobStatus) => {
    const steps = Object.keys(STEPS)
    setJobStatus(jobStatus)
    setReport(null)
    setCurrentStep((currentStep) => steps[steps.indexOf(currentStep) + 1])
  }

  const handleFilters = (column, option) => {
    let newMapping = { ...mapping }
    if (option.value === 'none') {
      delete newMapping[column]
    } else {
      newMapping[column] = option
    }
    setMapping(newMapping)
  }

  const handleCategories = (label) => {
    const isSelected = selectedCategories.includes(label)
    let updatedCategories

    if (isSelected) {
      updatedCategories = selectedCategories.filter(
        (category) => category !== label,
      )
    } else {
      const maxSelections = isFreeSubscription
        ? alwaysActiveCategories.length +
          subscriptionQuery?.data.level.options.rh.categorieLimit
        : selectedCategories.length + 1

      if (selectedCategories.length < maxSelections) {
        updatedCategories = [...selectedCategories, label]
      } else {
        return
      }
    }
    setSelectedCategories(updatedCategories)
  }

  const handleValidate = () => {
    const requiredVariables = ['ps_email', 'ps_matricule']

    if (setting) {
      requiredVariables.push(
        ...setting?.variables.map((variable) => variable.name),
      )
    }

    if (!mapping) return setMissingVariables(requiredVariables)

    const updatedMapping = Object.keys(mapping)
      .filter((key) => selectedCategories.includes(key))
      .reduce((acc, key) => {
        acc[key] = mapping[key]
        return acc
      }, {})

    const variables = Object.values(updatedMapping).map((f) => f.value)

    const missingVariables = requiredVariables.filter(
      (variable) => !variables.includes(variable),
    )

    if (!missingVariables.length) {
      const form = new FormData()
      form.append('file', rhFile)
      form.set('filters', JSON.stringify(updatedMapping))

      backend.parseRH
        .parse(baseId, form)
        .then((res) => finishRHMapping(res.data))
    } else {
      setMissingVariables(missingVariables)
    }
  }

  const finishStatus = (newReport) => {
    if (!report) {
      setReport(newReport)
      nextStep()
    }
  }

  const finishImport = () => {
    const steps = Object.keys(STEPS)
    setJobStatus(null)
    setRHFile(null)
    onReload()
    if (currentStep === steps[steps.length - 1]) {
      closeModal()
    } else {
      setCurrentStep((currentStep) => steps[steps.indexOf(currentStep) + 1])
    }
  }

  const handleStats = () => {
    const steps = Object.keys(STEPS)
    if (canCreateGroup) {
      setCurrentStep((currentStep) => steps[steps.indexOf(currentStep) + 1])
    } else {
      notification.success(t('notification.import'), 'lg')
      closeModal()
    }
  }

  const handleSubmit = async (values) => {
    try {
      for (let key of Object.keys(values)) {
        for (let group of Object.keys(values[key])) {
          if (values[key][group]) {
            const respondents = processedData
              .filter((respondent) => {
                if (date_categories_list.includes(key)) {
                  return respondent.rhData[key].group === group
                }
                return respondent.rhData[key] === group
              })
              .map((respondent) => respondent._id)

            base.createGroup.mutate({
              name: `${t(`category.${key}`)} - ${group}`,
              respondents,
            })
          }
        }
      }
      notification.success(t('notification.import'), 'lg')
    } catch (e) {
      notification.error(t('notification.error'), 'lg')
    }
    closeModal()
  }

  const handleConfirmChange = (event) => {
    setConfirm(event.target.checked)
  }

  let STEPS = {
    [SETTING]: (
      <ImportSetting
        setting={setting}
        confirm={confirm}
        onConfirm={handleConfirmChange}
      />
    ),
    [MODEL]: <ImportModel goNextStep={nextStep} />,
    [FILE]: <ImportFile onFirstParsing={handleFirstParsing} />,
    [MAPPING]: (
      <RHMapping
        missingVariables={missingVariables}
        onMissingVariables={setMissingVariables}
        columns={columns}
        options={options}
        mapping={mapping}
        selectedCategories={selectedCategories}
        onChangeSelectedCategories={handleCategories}
        onFilter={handleFilters}
        setting={setting}
      />
    ),
    [STATUS]: (
      <ImportStatus
        status={jobStatus}
        setStatus={setJobStatus}
        goNextStep={finishStatus}
        setError={setError}
        error={error}
        setting={setting}
      />
    ),
    [REPORT]: <ImportReport data={report} />,
  }

  if (!base.isSurvey || canCreateGroup) {
    STEPS = {
      ...STEPS,
      [STATS]: <RHStats baseId={base.id} />,
    }
    if (canCreateGroup) {
      STEPS = {
        ...STEPS,

        [GROUP]: (
          <CreateGroups
            groups={groups}
            onChangeGroups={setGroups}
            baseId={base.id}
          />
        ),
      }
    }
  }
  return (
    <Modal
      size={
        currentStep === SETTING ||
        currentStep === MAPPING ||
        currentStep === STATS ||
        currentStep === GROUP
          ? 'xl'
          : 'lg'
      }
      centered
      show={showImportModelModal}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('component.rhImport.title')}</Modal.Title>

        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id='InteractionButtons-tooltip' className='position-fixed'>
              {t('common.download')}
            </Tooltip>
          }
        >
          <FontAwesomeIcon
            icon={faArrowToBottom}
            onClick={() => downloadChart(ref.current)}
            className='cursor-pointer text-white'
          />
        </OverlayTrigger>
      </Modal.Header>
      <Modal.Body
        className={`bg-white ${currentStep === STATS && 'p-0'}`}
        ref={ref}
      >
        {STEPS[currentStep]}
      </Modal.Body>
      <Modal.Footer
        className={`justify-content-between pt-4 ${
          missingVariables.length && 'd-none'
        }`}
      >
        <div style={{ minWidth: '7rem' }}>
          <ButtonWithIcon
            icon={farChevronLeft}
            variant='secondary'
            className={classNames({
              'd-none':
                currentStep === SETTING ||
                (currentStep === STATUS && !error) ||
                currentStep === REPORT,
            })}
            onClick={() => previousStep()}
          >
            {t('component.rhImport.cta1')}
          </ButtonWithIcon>
        </div>

        <div className={`d-flex gap-3 ${currentStep === 0 ? 'me-0' : ' me-5'}`}>
          {Object.keys(STEPS).map((step) => (
            <div
              key={step}
              className={`p-1 rounded-circle ${
                step === currentStep ? 'bg-primary' : 'bg-dark'
              }`}
            />
          ))}
        </div>
        <div className='text-end' style={{ minWidth: '6rem' }}>
          <Button
            onClick={() => nextStep()}
            disabled={!canGo || (setting && !confirm)}
            className={classNames({
              'd-none': currentStep === MODEL || currentStep === STATUS,
            })}
          >
            {currentStep === Object.keys(STEPS)[Object.keys(STEPS).length - 1]
              ? t('component.rhImport.cta3')
              : t('component.rhImport.cta2')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default RHImport
