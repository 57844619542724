import { Scrollbar } from '@olystic/design_system_3'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function ListSettings({ setting }) {
  const { t } = useTranslation()
  return (
    <Scrollbar style={{ maxHeight: '40rem', paddingRight: '1rem' }}>
      <div className='d-flex flex-wrap gap-5'>
        {setting.variables.map((variable, index) => (
          <div key={index}>
            <div className='p-3 border border-1 bg-secondary bg-opacity-25'>
              <p className='m-0'>{t(`category.${variable.name}`)}</p>
            </div>
            <div className='d-flex justify-content-between align-items-center border border-1 border-top-0 p-3'>
              {variable.modalities && variable.modalities.length > 0 ? (
                <div>
                  <p className='mb-2'>{t('component.importSetting.list')}</p>
                  <div className='d-flex align-items-center gap-5 flex-wrap'>
                    {variable.modalities.map((modality, modIndex) => (
                      <div
                        key={modIndex}
                        className='text-decoration-underline fw-semibold text-primary'
                      >
                        {modality}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p className='mb-0 fw-semibold'>
                  {['ps_age', 'ps_anciennete'].includes(variable.name)
                    ? t('component.importSetting.date_modalities')
                    : t('component.importSetting.no_modalities')}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </Scrollbar>
  )
}
