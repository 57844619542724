import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { ReactComponent as Olystic } from '../../assets/icons/olystic_icon.svg'

export const EMAIL_CONTEXT_ANNONCE = 'config-questionnaire-annonce-Utilisateur'
export const EMAIL_CONTEXT_LANCEMENT =
  'config-questionnaire-lancement-Utilisateur'
export const EMAIL_CONTEXT_RELANCE = 'config-questionnaire-relance-Utilisateur'
export const EMAIL_CONTEXT_CLOTURE = 'config-questionnaire-cloture-Utilisateur'

export const types = [
  {
    id: EMAIL_CONTEXT_ANNONCE,
    title: 'item3',
    icon: (
      <FontAwesomeIcon icon={faEnvelope} className='text-primary fa-lg me-1' />
    ),
  },
  {
    id: EMAIL_CONTEXT_LANCEMENT,
    title: 'item4',
    icon: <Olystic className='mb-1' />,
  },
  {
    id: EMAIL_CONTEXT_RELANCE,
    title: 'item5',
    icon: <Olystic className='mb-1' />,
  },
  {
    id: EMAIL_CONTEXT_CLOTURE,
    title: 'item6',
    icon: <Olystic className='mb-1' />,
  },
]
