import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import style from './ParticipationByCategory.module.scss'
// Components
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Scrollbar } from '@olystic/design_system_3'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import {
  faUsers as fadUsers,
  faChartPieAlt as fadChartPieAlt,
} from '@fortawesome/pro-duotone-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'

ParticipationByCategory.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      responses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  minEffectif: PropTypes.number.isRequired,
}

export default function ParticipationByCategory({ data, minEffectif }) {
  const { t } = useTranslation()
  const [collapsedResultIds, setCollapsedResultIds] = React.useState([])

  const getResultToggler = (resultId) => {
    const collapsed = collapsedResultIds.includes(resultId)

    setCollapsedResultIds(
      collapsed
        ? collapsedResultIds.filter((response) => response !== resultId)
        : [...collapsedResultIds, resultId],
    )
  }

  const getColor = (value) => {
    if (value >= 90) {
      return 'darkGreen'
    } else if (value >= 60) {
      return 'green'
    } else if (value >= 30) {
      return 'yellow'
    } else {
      return 'red'
    }
  }

  return (
    <div className={style.container}>
      <Scrollbar style={{ maxHeight: '25rem', marginRight: '-3rem' }}>
        <div className={style.labelContainer}>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip>
                {t('component.participationRate.categories.tooltip1')}
              </Tooltip>
            }
          >
            <FontAwesomeIcon icon={fadUsers} />
          </OverlayTrigger>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip>
                {t('component.participationRate.categories.tooltip2')}
              </Tooltip>
            }
          >
            <FontAwesomeIcon icon={fadChartPieAlt} />
          </OverlayTrigger>
        </div>
        <div className={style.results}>
          {data.map((result) => (
            <div
              key={result.id}
              className={classnames(style.result, {
                [style.resultCollapsed]: collapsedResultIds.includes(result.id),
              })}
            >
              <div className={style.resultToggler}>
                <button
                  className={style.resultTogglerButton}
                  onClick={() => getResultToggler(result.id)}
                >
                  <FontAwesomeIcon
                    className={style.resultTogglerIcon}
                    icon={faCaretDown}
                  />
                  {t(`category.${result.name}`)}
                </button>
              </div>
              <div className={style.responseContainer}>
                {result.responses.map((response) => (
                  <div className={style.response} key={response.id}>
                    <div
                      className={classnames(style.responseName, {
                        'text-secondary': response.response < minEffectif,
                      })}
                    >
                      {response.name}
                    </div>

                    <div
                      className={classnames({
                        'text-secondary': response.response < minEffectif,
                      })}
                    >
                      {response.total}
                    </div>

                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip>
                          {t(
                            'component.participationRate.categories.nbRespondent_interval',
                            {
                              postProcess: 'interval',
                              count: response.response,
                            },
                          )}
                        </Tooltip>
                      }
                    >
                      <div
                        className={classnames(style.responseBar, {
                          [style.responseBarDisabled]:
                            response.response < minEffectif,
                        })}
                      >
                        <div
                          className={classnames(style.responseBarFill, {
                            [style.responseBarFillDisabled]:
                              response.response < minEffectif,
                            [`bg-${getColor(response.value)}`]:
                              response.response >= minEffectif,
                          })}
                          style={{
                            width: `${response.value.toFixed(2)}%`,
                          }}
                        />
                      </div>
                    </OverlayTrigger>

                    <div className={classnames('mx-3 text-blue-200')}>
                      {!isNaN(response.value) ? response.value : '-'}%
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Scrollbar>
    </div>
  )
}
